var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.bizType != "PLATFORM" && _vm.canView
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "text-bottom",
                    height: "50px",
                  },
                },
                [_c("TopAnnouncement")],
                1
              )
            : _vm._e(),
          _vm.bizType != "PLATFORM"
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "text-bottom",
                    height: "50px",
                  },
                },
                [_c("TopMasege")],
                1
              )
            : _vm._e(),
          _vm.device !== "mobile"
            ? [
                _c("theme-picker", {
                  staticClass: "right-menu-item hover-effect",
                  staticStyle: { display: "none" },
                  attrs: { id: "themePicker" },
                  on: { change: _vm.themeChange },
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-wrapper",
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { id: "avatarContainer" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "line-height": "18px",
                        "padding-right": "5px",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.refreshApp.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.userInfo.data
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                height: "15px",
                                "font-weight": "700",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.userInfo.data.orgName ||
                                    (_vm.userInfo.data.organization &&
                                      _vm.userInfo.data.organization.orgName) ||
                                    ""
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.userInfo.user
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                height: "15px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.userInfo.user.userName || ""))]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.userInfo.user && _vm.userInfo.user.headImageStorageUrl
                    ? _c("img", {
                        staticClass: "user-avatar",
                        attrs: { src: _vm.userInfo.user.headImageStorageUrl },
                      })
                    : _c("svg-icon", {
                        staticClass: "user-avatar",
                        attrs: { "icon-class": "defaultAvatar" },
                      }),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.portalHomePath } },
                    [_c("el-dropdown-item", [_vm._v("回到首页")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleCommand.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("密码修改")]
                  ),
                  _vm.canSwitchAccount
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openSwitchAccount.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("切换门店")]
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出系统"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showModifyPwdDialog,
            "close-on-click-modal": false,
            title: "修改密码",
            width: "480px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModifyPwdDialog = $event
            },
            close: _vm.closePwd,
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("修改密码")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "max-height": "550px" },
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "pwdForm",
                          attrs: {
                            model: _vm.pwdForm,
                            rules: _vm.formRules,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "旧密码：",
                                        prop: "oldPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "password",
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入旧密码",
                                          name: "password",
                                        },
                                        model: {
                                          value: _vm.pwdForm.oldPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pwdForm,
                                              "oldPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "pwdForm.oldPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "新密码：",
                                        prop: "newPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "password",
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入新密码",
                                          name: "password",
                                        },
                                        model: {
                                          value: _vm.pwdForm.newPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pwdForm,
                                              "newPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "pwdForm.newPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "确认密码：",
                                        prop: "confirmPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "password",
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入确认密码",
                                          name: "password",
                                        },
                                        model: {
                                          value: _vm.pwdForm.confirmPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pwdForm,
                                              "confirmPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "pwdForm.confirmPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验证码：",
                                        prop: "verificationCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "60%" },
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入验证码",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.pwdForm.verificationCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.pwdForm,
                                                  "verificationCode",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "pwdForm.verificationCode",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "codeImgBox" },
                                            [
                                              _vm.verifyCodeId
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        "data:image/jpg;base64," +
                                                        _vm.codeImg,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/images/404.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-refresh refreshButton",
                                                on: {
                                                  click: _vm.changeCodeImg,
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { height: "40px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "8px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-check",
                            plain: "",
                          },
                          on: { click: _vm.modifyPwd },
                        },
                        [_vm._v(" 确认 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-close" },
                          on: { click: _vm.closePwd },
                        },
                        [_vm._v(" 关闭 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showSwitchDialog,
            "close-on-click-modal": false,
            title: "切换门店",
            width: "720px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSwitchDialog = $event
            },
            close: function ($event) {
              _vm.showSwitchDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("切换门店")]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "app-m-cls",
              staticStyle: { "max-height": "550px" },
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: {
                    "max-height": "500px",
                    overflow: "auto",
                    "margin-bottom": "15px",
                  },
                },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "never" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "30px",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v("当前账户: ")]
                              ),
                              _vm._v(
                                _vm._s(
                                  _vm.accountInfo &&
                                    _vm.accountInfo.myUserAccount
                                ) + " "
                              ),
                              _c(
                                "el-tag",
                                {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "warning" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.accountInfo &&
                                        _vm.accountInfo.mainUserFlag
                                        ? "主"
                                        : "子"
                                    ) + "账户"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.loadAccountData()
                                    },
                                  },
                                },
                                [_vm._v("刷新")]
                              ),
                              _vm.accountInfo && _vm.accountInfo.mainUserFlag
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openAddSwitchAccount()
                                        },
                                      },
                                    },
                                    [_vm._v("新增")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "highlight-current-row": "",
                            size: "mini",
                            border: "",
                            data: _vm.tableData,
                            height: "350px",
                            "header-cell-style": { background: "#F7F7F7" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "userOrgName",
                              label: "所属机构",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "userAccount",
                              label: "登录账户",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "userName",
                              label: "姓名",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width:
                                "" +
                                (_vm.accountInfo && _vm.accountInfo.mainUserFlag
                                  ? 150
                                  : 90),
                              "show-overflow-tooltip": "",
                              align: "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "200",
                                          trigger: "click",
                                        },
                                        model: {
                                          value: scope.row.visible,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "visible", $$v)
                                          },
                                          expression: "scope.row.visible",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-table",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              size: "mini",
                                              border: "",
                                              data: _vm.portalData,
                                              "header-cell-style": {
                                                background: "#F7F7F7",
                                              },
                                              "highlight-current-row": "",
                                            },
                                            on: {
                                              "row-click": function (row) {
                                                _vm.switchLogin(row, scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                "min-width": "150",
                                                property: "portalName",
                                                label: "可登录站点",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0px 5px" },
                                            attrs: {
                                              slot: "reference",
                                              type: "primary",
                                              size: "mini",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.getLoginParam(
                                                  scope.row
                                                )
                                              },
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("切换")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.accountInfo &&
                                    _vm.accountInfo.mainUserFlag
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteRelational(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("解绑")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", {
                staticStyle: { height: "1px" },
                attrs: { slot: "footer" },
                slot: "footer",
              }),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddSwitchDialog,
            "close-on-click-modal": false,
            title: "用户绑定",
            width: "480px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddSwitchDialog = $event
            },
            close: function ($event) {
              _vm.showAddSwitchDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("用户绑定")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "max-height": "550px" },
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "bindForm",
                          attrs: {
                            model: _vm.bindForm,
                            rules: _vm.formRules,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户名：",
                                        prop: "account",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入用户名",
                                          name: "account",
                                        },
                                        model: {
                                          value: _vm.bindForm.account,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bindForm,
                                              "account",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "bindForm.account",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "密码：",
                                        prop: "password",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "password",
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入密码",
                                          name: "password",
                                        },
                                        model: {
                                          value: _vm.bindForm.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bindForm,
                                              "password",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "bindForm.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验证码：",
                                        prop: "verificationCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "60%" },
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入验证码",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.bindForm.verificationCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.bindForm,
                                                  "verificationCode",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "bindForm.verificationCode",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "codeImgBox" },
                                            [
                                              _vm.verifyCodeId
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        "data:image/jpg;base64," +
                                                        _vm.codeImg,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/images/404.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-refresh refreshButton",
                                                on: {
                                                  click: _vm.changeCodeImg,
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { height: "40px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "8px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.showAddSwitchDialog = false
                            },
                          },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            loading: _vm.submitting,
                            plain: "",
                          },
                          on: { click: _vm.submitBindUser },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.submitting ? "正在提交" : "绑定") +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "msgDialog",
          attrs: {
            visible: _vm.showAnnounceDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAnnounceDialog = $event
            },
            close: _vm.announceClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "font-size": "15px",
                    "text-align": "left",
                    display: "flex",
                  },
                },
                [
                  _c("svg-icon", {
                    staticStyle: {
                      "font-size": "18px",
                      "margin-right": "10px",
                      color: "#edcb4f",
                    },
                    attrs: { "icon-class": "notice" },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "margin-right": "10px",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(_vm._s(_vm.noticeInfo.noticeTitle || "-"))]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "300px",
                "min-height": "120px",
                "overflow-y": "auto",
                "line-height": "30px",
                "white-space": "pre-wrap",
              },
            },
            [_vm._v(" " + _vm._s(_vm.noticeInfo.noticeContent) + " ")]
          ),
          _vm.noticeInfo.noticeVideoUrl
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "white-space": "pre-wrap",
                  },
                },
                [
                  _vm._v("视频地址(URL)： "),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#409eff", cursor: "pointer" },
                      on: { click: _vm.viewWatchVideo },
                    },
                    [_vm._v(_vm._s(_vm.noticeInfo.noticeVideoUrl || "-"))]
                  ),
                ]
              )
            : _vm._e(),
          !_vm._.isEmpty(_vm.noticeInfo.noticeFileList)
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "white-space": "pre-wrap",
                  },
                },
                [
                  _c("div", [_vm._v("附件列表：")]),
                  _vm._l(_vm.noticeInfo.noticeFileList, function (file, i) {
                    return _c("div", { key: i }, [
                      _c("div", { staticClass: "file-box" }, [
                        _c(
                          "span",
                          {
                            staticClass: "file-text",
                            attrs: { title: file.fileName },
                            on: {
                              click: function ($event) {
                                return _vm.handle(file)
                              },
                            },
                          },
                          [_vm._v(_vm._s(file.fileName))]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "margin-top": "8px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "flex-start",
                    "align-items": "center",
                  },
                },
                [
                  !_vm._.isEmpty(_vm.noticeInfo.noticeFileList)
                    ? _c("i", {
                        staticClass: "el-icon-paperclip",
                        staticStyle: { "font-size": "22px", color: "#acacac" },
                      })
                    : _vm._e(),
                  _vm.noticeInfo.createdTime
                    ? _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(
                          "发送时间：" + _vm._s(_vm.noticeInfo.createdTime)
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showWatchVideoDialog,
            "close-on-click-modal": false,
            width: "880px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showWatchVideoDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看视频")]
              ),
            ]
          ),
          _c("WatchVideo", {
            attrs: {
              videoItem: _vm.videoItem,
              refresh: _vm.showWatchVideoDialog,
            },
            on: {
              closePage: function ($event) {
                _vm.showWatchVideoDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }