"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/toConsumableArray.js"));
var _slicedToArray2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/slicedToArray.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/web.dom-collections.iterator.js");
var state = {
  visitedViews: [],
  cachedViews: []
};
var mutations = {
  ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
    if (state.visitedViews.some(function (v) {
      return v.fullPath === view.fullPath;
    })) return;
    var _title = view.meta.title;
    //如果有车牌号或者车架号，则作为tab名称后缀
    if (view.query && view.query) {
      var plateNo = view.query.plateNo || '';
      plateNo = plateNo == '未上牌' ? '' : plateNo;
      var vin = view.query.vin || '';
      if (plateNo || vin) {
        _title = view.meta.title + ' - ' + (plateNo || vin);
      }
      var ownerName = view.query.ownerName || '';
      var policyId = view.query.policyId || '';
      var riskType = view.query.riskType || '';
      var showName = view.query.isShowName || '';
      if (policyId && riskType) {
        _title = view.meta.title + ' - ' + (plateNo || vin) + ' - ' + (riskType == '0' ? '交强险' : riskType == '2' ? "非车险" : "商业险");
      }
      if (showName) {
        _title = view.meta.title + ' - ' + ownerName;
      }
      var tabName = view.query.tabName || '';
      if (tabName) {
        _title = tabName;
      }
    }
    state.visitedViews.push(Object.assign({}, view, {
      title: _title || 'no-name'
    }));
  },
  ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
    var _iterator = (0, _createForOfIteratorHelper2.default)(state.visitedViews.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = (0, _slicedToArray2.default)(_step.value, 2),
          i = _step$value[0],
          v = _step$value[1];
        if (v.fullPath.indexOf(view.fullPath) != -1) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  },
  DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
    var _iterator2 = (0, _createForOfIteratorHelper2.default)(state.cachedViews),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var i = _step2.value;
        if (i === view.name) {
          var index = state.cachedViews.indexOf(i);
          state.cachedViews.splice(index, 1);
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  },
  DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
    // state.visitedViews = state.visitedViews.filter(v => {
    //     return v.meta.affix || v.fullPath.indexOf(view.fullPath) != -1
    // })
    var _iterator3 = (0, _createForOfIteratorHelper2.default)(state.visitedViews),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var i = _step3.value;
        if (i.name === view.name) {
          var index = state.visitedViews.indexOf(i);
          state.visitedViews = state.visitedViews.slice(index, index + 1);
          break;
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  },
  DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
    var _iterator4 = (0, _createForOfIteratorHelper2.default)(state.cachedViews),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var i = _step4.value;
        if (i === view.name) {
          var index = state.cachedViews.indexOf(i);
          state.cachedViews = state.cachedViews.slice(index, index + 1);
          break;
        }
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
  },
  DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
    // keep affix tags
    var affixTags = state.visitedViews.filter(function (tag) {
      return tag.meta.affix;
    });
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
    state.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {
    var _iterator5 = (0, _createForOfIteratorHelper2.default)(state.visitedViews),
      _step5;
    try {
      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
        var v = _step5.value;
        if (v.fullPath.indexOf(view.fullPath) != -1) {
          v = Object.assign(v, view);
          break;
        }
      }
    } catch (err) {
      _iterator5.e(err);
    } finally {
      _iterator5.f();
    }
  }
};
var actions = {
  addView: function addView(_ref, view) {
    var dispatch = _ref.dispatch;
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView: function addVisitedView(_ref2, view) {
    var commit = _ref2.commit;
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView: function addCachedView(_ref3, view) {
    var commit = _ref3.commit;
    commit('ADD_CACHED_VIEW', view);
  },
  delView: function delView(_ref4, view) {
    var dispatch = _ref4.dispatch,
      state = _ref4.state;
    return new Promise(function (resolve) {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      clearCache(view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delVisitedView: function delVisitedView(_ref5, view) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve) {
      commit('DEL_VISITED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delCachedView: function delCachedView(_ref6, view) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve) {
      commit('DEL_CACHED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  delOthersViews: function delOthersViews(_ref7, view) {
    var dispatch = _ref7.dispatch,
      state = _ref7.state;
    return new Promise(function (resolve) {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      clearOtherCache(view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delOthersVisitedViews: function delOthersVisitedViews(_ref8, view) {
    var commit = _ref8.commit,
      state = _ref8.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delOthersCachedViews: function delOthersCachedViews(_ref9, view) {
    var commit = _ref9.commit,
      state = _ref9.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  delAllViews: function delAllViews(_ref10, view) {
    var dispatch = _ref10.dispatch,
      state = _ref10.state;
    return new Promise(function (resolve) {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      clearAllCache();
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delAllVisitedViews: function delAllVisitedViews(_ref11) {
    var commit = _ref11.commit,
      state = _ref11.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delAllCachedViews: function delAllCachedViews(_ref12) {
    var commit = _ref12.commit,
      state = _ref12.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  updateVisitedView: function updateVisitedView(_ref13, view) {
    var commit = _ref13.commit;
    commit('UPDATE_VISITED_VIEW', view);
  }
};

/**
 * 清除缓存（单个关闭标签）
 * @param {} view 
 */
function clearCache(view) {
  if (!view) return;
  if (view.path == "/serviceIssue/tireServiceIssue") {
    setTimeout(function () {
      sessionStorage.removeItem('LTFWForm');
    }, 500);
  }
  if (view.path == "/serviceIssue/IntelligentDrivingServiceIssue") {
    setTimeout(function () {
      sessionStorage.removeItem('ZJFWForm');
    }, 500);
  }
  if (view.path == "/serviceIssue/completeVehicle") {
    setTimeout(function () {
      sessionStorage.removeItem('ZCYBFWForm');
    }, 500);
  }
  if (view.path == "/serviceIssue/sanelectricWarranty") {
    setTimeout(function () {
      sessionStorage.removeItem('SDYBFWForm');
    }, 500);
  }
  if (view.path == "/serviceIssue/comprehensiveWarranty") {
    setTimeout(function () {
      sessionStorage.removeItem('QMYBFWForm');
    }, 500);
  }
  if (view.path == "/serviceIssue/rangeExtendedVehicleIssue") {
    setTimeout(function () {
      sessionStorage.removeItem('ERYBFWForm');
    }, 500);
  }
}
/**
 * 清除缓存（关闭其他标签）
 * @param {} view 
 */
function clearOtherCache(view) {
  if (!view) return;
  setTimeout(function () {
    if (view.path == "/serviceIssue/tireServiceIssue") {
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
    if (view.path == "/serviceIssue/IntelligentDrivingServiceIssue") {
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
    if (view.path == "/serviceIssue/completeVehicle") {
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
    if (view.path == "/serviceIssue/sanelectricWarranty") {
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
    if (view.path == "/serviceIssue/comprehensiveWarranty") {
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
    if (view.path == "/serviceIssue/rangeExtendedVehicleIssue") {
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
    }
  }, 500);
}
/**
 * 清除缓存（全部关闭标签）
 * @param {} view 
 */
function clearAllCache() {
  setTimeout(function () {
    if (!["/serviceIssue/tireServiceIssue", "/serviceIssue/IntelligentDrivingServiceIssue", "/serviceIssue/completeVehicle", "/serviceIssue/sanelectricWarranty", "/serviceIssue/comprehensiveWarranty", "/serviceIssue/rangeExtendedVehicleIssue"].includes(view.path)) {
      // console.log('不是出单页面关闭的')
      sessionStorage.removeItem('LTFWForm');
      sessionStorage.removeItem('ZJFWForm');
      sessionStorage.removeItem('ZCYBFWForm');
      sessionStorage.removeItem('SDYBFWForm');
      sessionStorage.removeItem('QMYBFWForm');
      sessionStorage.removeItem('ERYBFWForm');
    }
  }, 500);
}
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};