var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-authorization" }, [
    _c("div", {
      staticClass: "bgImgContainer",
      style: {
        "background-image": "url(" + _vm.imgSrc + ")",
        "background-size": "cover",
        height: "100vh",
      },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "from-information",
        style: {
          padding: _vm.activeSelect == "register" ? "37px 40px" : "10px 40px",
        },
        attrs: {
          "element-loading-text": "正在校验中，请稍等...",
          "element-loading-spinner": "el-icon-loading",
        },
      },
      [
        _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: _vm.logo },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                margin: "10px 0",
                "word-break": "break-all",
              },
            },
            [
              _vm._v(
                "欢迎" +
                  _vm._s(
                    _vm.allParams.userName || _vm.allParams.userAccount || "-"
                  )
              ),
            ]
          ),
          _c("div", { staticClass: "flex-between" }, [
            _c(
              "div",
              {
                staticStyle: {
                  flex: "1",
                  padding: "15px 0",
                  cursor: "pointer",
                  "box-sizing": "border-box",
                },
                style: {
                  color:
                    _vm.activeSelect == "login" ? _vm.themeColor : "#515151",
                  "border-bottom":
                    _vm.activeSelect == "login"
                      ? "1px solid " + _vm.themeColor
                      : "none",
                },
                on: {
                  click: function ($event) {
                    _vm.activeSelect = "login"
                  },
                },
              },
              [_vm._v("新建保险平台账号")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  flex: "1",
                  padding: "15px 0",
                  cursor: "pointer",
                  "box-sizing": "border-box",
                },
                style: {
                  color:
                    _vm.activeSelect == "register" ? _vm.themeColor : "#515151",
                  "border-bottom":
                    _vm.activeSelect == "register"
                      ? "1px solid " + _vm.themeColor
                      : "none",
                },
                on: {
                  click: function ($event) {
                    _vm.activeSelect = "register"
                  },
                },
              },
              [_vm._v("已有保险平台账号")]
            ),
          ]),
        ]),
        _vm.activeSelect == "login"
          ? _c(
              "el-form",
              {
                ref: "login_form",
                attrs: { model: _vm.allParams, "label-width": "85px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户名称：" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.allParams.userName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.allParams,
                            "userName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "allParams.userName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "用户账号：" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.allParams.userAccount,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.allParams,
                            "userAccount",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "allParams.userAccount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "机构编码：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.allParams.thirdOrgCode,
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "机构名称：" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.allParams.orgName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.allParams,
                            "orgName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "allParams.orgName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "0px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("新建登录")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.activeSelect == "register"
          ? _c(
              "el-form",
              {
                key: "registerForm",
                ref: "register_form",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "", prop: "account", "label-width": "0px" },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        ref: "account",
                        attrs: {
                          maxlength: "40",
                          placeholder: "登录账号",
                          name: "account",
                          type: "text",
                          tabindex: "1",
                          "auto-complete": "on",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.account,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "account",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.account",
                        },
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "el-icon-user",
                            staticStyle: { "font-size": "16px" },
                          }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "",
                      prop: "password",
                      "label-width": "0px",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          maxlength: "40",
                          placeholder: "密码",
                          name: "password",
                          tabindex: "2",
                          "auto-complete": "on",
                          type: _vm.passwordType,
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.save.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password",
                        },
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "el-icon-unlock",
                            staticStyle: { "font-size": "16px" },
                          }),
                        ]),
                        _c("template", { slot: "append" }, [
                          _c(
                            "div",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "",
                      prop: "verificationCode",
                      "label-width": "0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "60%" },
                          attrs: {
                            maxlength: "40",
                            placeholder: "验证码",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.save.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.verificationCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "verificationCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.verificationCode",
                          },
                        }),
                        _c("div", { staticClass: "codeImgBox" }, [
                          _vm.form.verifyCodeId
                            ? _c("img", {
                                staticStyle: { width: "80%", height: "30px" },
                                attrs: {
                                  src: "data:image/jpg;base64," + _vm.codeImg,
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                staticStyle: { width: "80%", height: "30px" },
                                attrs: {
                                  src: require("@/assets/images/404.png"),
                                  alt: "",
                                },
                              }),
                          _c("i", {
                            staticClass: "el-icon-refresh refreshButton",
                            on: { click: _vm.changeCodeImg },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "0px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("绑定登录")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }