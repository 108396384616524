"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.starts-with.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _qiankun = require("qiankun");
var _default = exports.default = {
  data: function data() {
    return {
      subappRegistry: [],
      lifeCycles: {}
    };
  },
  mounted: function mounted() {
    this.runSubApp();
  },
  methods: {
    getActiveRule: function getActiveRule(routerPrefix) {
      return function (location) {
        return location.pathname.startsWith("".concat(routerPrefix));
      };
    },
    runSubApp: function runSubApp() {
      var _this = this;
      var self = this;
      var props = {
        goMainApp: function goMainApp() {
          // console.log('goMainApp');
          self.$router.replace('/');
        },
        goSubApp: function goSubApp(url) {
          // console.log('goSubApp=>:', url);
          self.$router.replace(url ? url : '/');
        },
        openDialog: function openDialog(obj) {
          // console.log('openDialog=>:', obj);
          self.$store.commit('workSubApp/SET_OPEN_DIALOG', true);
          self.$store.commit('workSubApp/SET_APP_CONTENT', obj);
          self.$store.commit('workSubApp/SET_APP_LOADING', true);
        },
        closeTagView: function closeTagView(type, data) {
          self.$store.dispatch(type, data);
          // if (self.$store.state.tagsView && self.$store.state.tagsView.visitedViews) {
          //     const _visitedViews = self.$store.state.tagsView.visitedViews;
          //     // if (!_.isEmpty(_visitedViews) && _visitedViews.length >= 1) {
          //         self.$router.replace(_visitedViews[_visitedViews.length - 1].fullPath);
          //     } else {
          //         self.$router.replace('/dashboard');
          //     }
          // }
        },
        logOut: function logOut(type, data) {
          var hasLogout = localStorage.getItem('logout') || false;
          self.$store.dispatch('user/logout');
          self.$store.dispatch('tagsView/delAllCachedViews', self.$router);
          if (hasLogout) {
            self.$router.push("/login?logout=1");
          } else {
            self.$router.push("/login");
          }
        }
      };
      var roleData = JSON.parse(localStorage.getItem('roleData'));
      if (roleData) {
        //获取微应用信息
        roleData.forEach(function (role) {
          if (role.subMenuList) {
            role.subMenuList.forEach(function (app, index) {
              if (app.microAppCode) {
                var temp = _.find(_this.subappRegistry, function (item) {
                  return item.name == app.microAppCode;
                });
                if (!temp) {
                  _this.subappRegistry.push({
                    name: app.microAppCode,
                    entry: app.microAppDomain,
                    container: '#subapp-viewport',
                    activeRule: _this.getActiveRule('/' + app.microAppCode),
                    props: props
                  });
                }
              }
            });
          }
        });
        this.$store.commit('workSubApp/SET_APP_LOADING', true);
        // console.log('subapps=>:', this.subappRegistry);
      }
      this.lifeCycles = {
        beforeLoad: [function (app) {
          var name = app.name;
          // console.log("正打开子应用: " + name);
          _this.$store.commit('workSubApp/SET_APP_LOADING', true);
        }],
        beforeMount: [function (app) {
          var name = app.name;
          // console.log("已打开子应用: " + name);
          sessionStorage.setItem('currentSubApp', name);
          _this.$store.commit('workSubApp/SET_APP_LOADING', false);
          _this.$store.commit('workSubApp/SET_IS_CHILD_APP', true);
        }],
        afterUnmount: [function (app) {
          var name = app.name;
          // console.log("已离开子应用: " + name);
          _this.$store.commit('workSubApp/SET_IS_CHILD_APP', false);
        }]
      };
      // 注册子应用
      (0, _qiankun.registerMicroApps)(this.subappRegistry, this.lifeCycles);
      // 初始化 state

      var actions = (0, _qiankun.initGlobalState)(props);
      actions.onGlobalStateChange(function (state, prev) {
        // state: 变更后的状态; prev 变更前的状态
        // console.log("main-onGlobalStateChange", state, prev);
      });
      // 在主加载后默认处于活动状态
      (0, _qiankun.setDefaultMountApp)('/');
      // 第一个应用构建完成后执行
      (0, _qiankun.runAfterFirstMounted)(function () {
        return console.info('first app mounted');
      });
      //启动
      (0, _qiankun.start)({
        prefetch: true,
        // boolean | 'all' | string[]
        sandbox: {
          // strictStyleIsolation = false，才可以获取到子应用的dom节点，主应用可修改子应用样式，但是子应用不可修改主应用的样式。
          strictStyleIsolation: false,
          experimentalStyleIsolation: true
        }
      });
    }
  }
};