"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * portal管理接口
 */

var objApi = {
  // ******************************* 保险平台接口 ***********************************
  /**
   * 授权登录接口
   */
  getOauth2Code: function getOauth2Code(registrationId, data) {
    return _request.default.get((0, _getUrl.default)("/oauth2/code/".concat(registrationId), 2), {
      params: data
    });
  },
  /**
   * 创建新用户
   */
  createNewUser: function createNewUser(data) {
    return _request.default.post((0, _getUrl.default)("/oauth2/bindingRel/createNewUser", 2), data);
  },
  /**
   * 绑定现有账户
   */
  bindLocalUser: function bindLocalUser(data) {
    return _request.default.post((0, _getUrl.default)("/oauth2/bindingRel/bindLocalUser", 2), data);
  }
};
var _default = exports.default = objApi;