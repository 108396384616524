"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 非车险模块列表
 */

var objApi = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
  /**
   * 获取车险产品列表
   */
  getInsuranceProductLists: function getInsuranceProductLists(data) {
    return _request.default.get((0, _getUrl.default)("/scheme/insurance/search", 17), {
      params: data
    });
  },
  /**
   * 车险产品新增
   */
  InsuranceProductAdd: function InsuranceProductAdd(data) {
    return _request.default.post((0, _getUrl.default)("/scheme/insurance/add", 17), data);
  },
  /**
   * 车险产品详情
   */
  getInsuranceProductDetail: function getInsuranceProductDetail(ids) {
    return _request.default.get((0, _getUrl.default)("/scheme/insurance/get?insuranceSchemeId=".concat(ids), 17));
  },
  /**
   * 车险产品删除
   */
  InsuranceProductDel: function InsuranceProductDel(ids) {
    return _request.default.delete((0, _getUrl.default)("/scheme/insurance/delete?ids=".concat(ids), 17));
  },
  /**
   * 车险产品修改
   */
  InsuranceProductUpdate: function InsuranceProductUpdate(ids, data) {
    return _request.default.put((0, _getUrl.default)("/scheme/insurance/update?insuranceSchemeId=".concat(ids), 17), data);
  },
  /**
   * 查询驾意险方案分页列表
   */
  getAccidentLists: function getAccidentLists(data) {
    return _request.default.get((0, _getUrl.default)("/scheme/accident", 17), {
      params: data
    });
  },
  /**
   * 驾意险方案新增
   */
  accidentAdd: function accidentAdd(data) {
    return _request.default.post((0, _getUrl.default)("/scheme/accident", 17), data);
  },
  /**
   * 驾意险方案详情
   */
  getAccidentDetail: function getAccidentDetail(id) {
    return _request.default.get((0, _getUrl.default)("/scheme/accident/".concat(id), 17));
  },
  /**
   * 驾意险方案删除
   */
  AccidentDel: function AccidentDel(id) {
    return _request.default.delete((0, _getUrl.default)("/scheme/accident?ids=".concat(id), 17));
  },
  /**
   * 驾意险方案编辑
   */
  AccidentUpdate: function AccidentUpdate(id, data) {
    return _request.default.put((0, _getUrl.default)("/scheme/accident/".concat(id), 17), data);
  },
  /**
   * 查询非车险方案分页列表
   */
  packageLists: function packageLists(data) {
    return _request.default.get((0, _getUrl.default)("/servicePack/scheme/search", 17), {
      params: data
    });
  },
  /**
   * 新增非车险方案
   */
  packageAdd: function packageAdd(data) {
    return _request.default.post((0, _getUrl.default)("/servicePack/scheme/add", 17), data);
  },
  /**
   * 非车险方案详情
   */
  packageDetail: function packageDetail(id) {
    return _request.default.get((0, _getUrl.default)("/servicePack/scheme/get?packSchemeId=".concat(id), 17));
  },
  /**
   * 非车险方案编辑
   */
  packageUpdate: function packageUpdate(id, data) {
    return _request.default.put((0, _getUrl.default)("/servicePack/scheme/update?packSchemeId=".concat(id), 17), data);
  },
  /**
   * 非车险方案启用禁用
   */
  packageEnabled: function packageEnabled(id, enabled) {
    return _request.default.put((0, _getUrl.default)("/servicePack/scheme/enabled?packSchemeId=".concat(id, "&enabled=").concat(enabled), 17));
  },
  /**
   * 非车险方案删除
   */
  packageDel: function packageDel(ids) {
    return _request.default.delete((0, _getUrl.default)("/servicePack/scheme/delete?ids=".concat(ids), 17));
  },
  /**
   * 非车险订单列表
   */
  getServicePackOrderList: function getServicePackOrderList(data) {
    return _request.default.get((0, _getUrl.default)("/servicePack/order/search", 17), {
      params: data
    });
  },
  /**
   * 查看非车险订单详情
   */
  getServPackOrderDetail: function getServPackOrderDetail(id) {
    return _request.default.get((0, _getUrl.default)("/servicePack/order/getServPackOrder?servPackOrderId=".concat(id), 17));
  },
  /**
   * 拉取(内部/外部)非车险方案
   */
  getServicePacksList: function getServicePacksList(data) {
    return _request.default.post((0, _getUrl.default)("/servicePack/scheme/getServPackPro", 17), data);
  },
  // -------------------------------新非车险产品----------------------------
  /**
   * 拉取非车产品
   */
  getAllNonProductList: function getAllNonProductList() {
    return _request.default.get((0, _getUrl.default)("/product/search", 16));
  },
  // -------------------------------新非车险投保----------------------------
  /**
   * 获取非车险
   */
  getServPackByVhcSeries: function getServPackByVhcSeries(data) {
    return _request.default.get((0, _getUrl.default)("/servicePack/scheme/getServPackByVhcSeries", 17), {
      params: data
    });
  },
  /**
   * 报价
   */
  quote: function quote(data) {
    return _request.default.post((0, _getUrl.default)("/servicePack/order/placeOrder", 17), data);
  },
  /**
   * 获取非车险详情
   */
  getServicePackById: function getServicePackById(id) {
    return _request.default.get((0, _getUrl.default)("/servicePack/scheme/get?packSchemeId=".concat(id), 17));
  },
  // ************************非车险运营平台临时包维护接口**************************

  /**
   * 分页查询
   */
  getTempServicePacksList: function getTempServicePacksList(data) {
    return _request.default.get((0, _getUrl.default)("/servpack/pro/temp/search", 17), {
      params: data
    });
  },
  /**
   * 新增
   */
  addTempServicePacks: function addTempServicePacks(data) {
    return _request.default.post((0, _getUrl.default)("/servpack/pro/temp/create", 17), data);
  },
  /**
   * 查询一条
   */
  queryTempServicePacks: function queryTempServicePacks(id) {
    return _request.default.get((0, _getUrl.default)("/servpack/pro/temp/get?id=".concat(id), 17));
  },
  /**
   * 更新
   */
  editTempServicePacks: function editTempServicePacks(data) {
    return _request.default.post((0, _getUrl.default)("/servpack/pro/temp/update", 17), data);
  },
  /**
   * 批量删除
   */
  deleteTempServicePacks: function deleteTempServicePacks(ids) {
    return _request.default.delete((0, _getUrl.default)("/servpack/pro/temp/batchDelete/".concat(ids), 17));
  },
  /**
   * 批量导入
   */
  importTempServicePacks: function importTempServicePacks(data) {
    return _request.default.post((0, _getUrl.default)("/servpack/pro/temp/import", 17), data);
  },
  /**
   * 分页查询非车险台账
   */
  getServicePackAccountList: function getServicePackAccountList(data) {
    return _request.default.get((0, _getUrl.default)("/servPack/account/search", 17), {
      params: data
    });
  },
  /**
   * 非车险统计看板分页查询
   */
  getServicePackStatistics: function getServicePackStatistics(data) {
    return _request.default.get((0, _getUrl.default)("/servicePack/statistics/search", 17), {
      params: data
    });
  },
  /**
   * 非车险统计看板汇总数据
   */
  getServicePackStatisticsCount: function getServicePackStatisticsCount(data) {
    return _request.default.get((0, _getUrl.default)("/servicePack/statistics/count", 17), {
      params: data
    });
  },
  /**
   * 非车险确认
   */
  confirmServicePack: function confirmServicePack(data) {
    return _request.default.post((0, _getUrl.default)("/servicePack/order/confirm", 17), data);
  },
  /**
   * 获取支付链接
   * @param {*} data
   */
  paymentLink: function paymentLink(data) {
    return _request.default.post((0, _getUrl.default)("/servicePack/order/payment", 17), data);
  },
  // ---------------------非车险确认---------------------------
  /**
  * 刷新订单
  * @param {*} orderNo
  */
  getServicePackDetail: function getServicePackDetail(orderNo) {
    return _request.default.get((0, _getUrl.default)("/servicePack/order/refreshStatus?servPackOrderNo=".concat(orderNo), 17));
  },
  /**
   * 生成支付信息
   * @param {*} data
   */
  getPayInfoBase64: function getPayInfoBase64(orderNo) {
    return _request.default.post((0, _getUrl.default)("/servicePack/order/generatePaymentParam?servPackOrderNo=".concat(orderNo), 17));
  }
}, "paymentLink", function paymentLink(data) {
  return _request.default.post((0, _getUrl.default)("/servicePack/order/payment", 17), data);
}), "refreshOrderState", function refreshOrderState(orderNo) {
  return _request.default.post((0, _getUrl.default)("/servicePack/order/insuredConfirmed?servPackOrderNo=".concat(orderNo), 17));
}), "paymentTransfer", function paymentTransfer(data) {
  return _request.default.post((0, _getUrl.default)("/servicePack/order/paymentTransfer", 15), data);
});
var _default = exports.default = objApi;