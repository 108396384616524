var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "click",
            placement: "bottom-start",
            "popper-class": "messageTopAnnBox",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "msg-list" }, [
            _c("div", { staticClass: "header" }, [
              _c("span", [_vm._v("公告通知")]),
            ]),
            !_vm._.isEmpty(_vm.tableData)
              ? _c(
                  "div",
                  {
                    staticClass: "messageDataList",
                    staticStyle: { "min-height": "150px" },
                  },
                  [
                    _vm._l(_vm.tableData, function (o, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "item",
                          on: {
                            click: function ($event) {
                              return _vm.getDetail(o)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "msg-left" }, [
                            _c("div", [_vm._v(_vm._s(o.noticeTitle))]),
                            _c("div", { staticClass: "msg-date" }, [
                              _vm._v(_vm._s(o.createdTime)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    _c("div", {
                      staticStyle: { width: "100%", height: "80px" },
                    }),
                  ],
                  2
                )
              : _c(
                  "div",
                  {
                    staticClass: "no-data",
                    staticStyle: {
                      "text-align": "center",
                      "line-height": "80px",
                    },
                  },
                  [
                    _vm._v(" 暂无新公告 "),
                    _c("div", {
                      staticStyle: { width: "100%", height: "50px" },
                    }),
                  ]
                ),
            _c(
              "div",
              {
                staticClass: "bottom fixedbottom",
                on: { click: _vm.viewDetail },
              },
              [
                _c("el-link", { attrs: { type: "primary" } }, [
                  _vm._v("查看全部"),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "msg-box",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "公告",
                    placement: "bottom",
                    effect: "light",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.total
                        ? _c(
                            "el-badge",
                            {
                              staticClass: "spot",
                              attrs: { value: _vm.total, type: "primary" },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  "font-size": "24px",
                                  "margin-right": "6px",
                                  color: "#000",
                                },
                                attrs: { "icon-class": "notice" },
                              }),
                            ],
                            1
                          )
                        : _c("svg-icon", {
                            staticStyle: {
                              "font-size": "24px",
                              "margin-right": "6px",
                              color: "#000",
                            },
                            attrs: { "icon-class": "notice" },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "msgDialog",
          attrs: {
            visible: _vm.showMsgDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMsgDialog = $event
            },
            close: function ($event) {
              _vm.showMsgDialog = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "dialogFooterTitle" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: {
                        "font-size": "18px",
                        "margin-right": "10px",
                        color: "#edcb4f",
                      },
                      attrs: { "icon-class": "notice" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "font-size": "15px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(_vm._s(_vm.noticeInfo.noticeTitle))]
                ),
              ]),
            ]
          ),
          _c("div", {
            staticStyle: {
              "max-height": "300px",
              "min-height": "120px",
              "overflow-y": "auto",
              "line-height": "30px",
              "white-space": "pre-wrap",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeInfo.noticeContent) },
          }),
          _vm.noticeInfo.noticeVideoUrl
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "white-space": "pre-wrap",
                  },
                },
                [
                  _vm._v("视频地址(URL)： "),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#409eff", cursor: "pointer" },
                      on: { click: _vm.viewWatchVideo },
                    },
                    [_vm._v(_vm._s(_vm.noticeInfo.noticeVideoUrl || "-"))]
                  ),
                ]
              )
            : _vm._e(),
          !_vm._.isEmpty(_vm.noticeInfo.noticeFileList)
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "white-space": "pre-wrap",
                  },
                },
                [
                  _c("div", [_vm._v("附件列表：")]),
                  _vm._l(_vm.noticeInfo.noticeFileList, function (file, i) {
                    return _c("div", { key: i }, [
                      _c("div", { staticClass: "file-box" }, [
                        _c(
                          "span",
                          {
                            staticClass: "file-text",
                            attrs: { title: file.fileName },
                            on: {
                              click: function ($event) {
                                return _vm.handle(file)
                              },
                            },
                          },
                          [_vm._v(_vm._s(file.fileName))]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "flex-start",
                    "align-items": "center",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-paperclip",
                    staticStyle: { "font-size": "18px", color: "#acacac" },
                  }),
                  _vm.noticeInfo.createdTime
                    ? _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(
                          "发送时间：" + _vm._s(_vm.noticeInfo.createdTime)
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showWatchVideoDialog,
            "close-on-click-modal": false,
            width: "880px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showWatchVideoDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看视频")]
              ),
            ]
          ),
          _c("WatchVideo", {
            attrs: {
              videoItem: _vm.videoItem,
              refresh: _vm.showWatchVideoDialog,
            },
            on: {
              closePage: function ($event) {
                _vm.showWatchVideoDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }