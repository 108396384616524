"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.json.stringify.js");
var _subapp = _interopRequireDefault(require("./subapp"));
var _users = _interopRequireDefault(require("@/api/users"));
var _common = _interopRequireDefault(require("@/api/common"));
var _watermark = require("@/utils/watermark");
var _common2 = require("@/utils/common");
var _VI_VEHICLE_KIND = _interopRequireDefault(require("@/assets/json/VI_VEHICLE_KIND"));
var _VI_USE_ATTRIBUTE = _interopRequireDefault(require("@/assets/json/VI_USE_ATTRIBUTE"));
var _VI_CERTIFICATE_TYPE = _interopRequireDefault(require("@/assets/json/VI_CERTIFICATE_TYPE"));
var _VI_PLATE_TYPE = _interopRequireDefault(require("@/assets/json/VI_PLATE_TYPE"));
var _VI_PLATE_COLOR = _interopRequireDefault(require("@/assets/json/VI_PLATE_COLOR"));
var _VI_ENGRGY_TYPE = _interopRequireDefault(require("@/assets/json/VI_ENGRGY_TYPE"));
var _VI_USE_ATTRIBUT_DETAIL = _interopRequireDefault(require("@/assets/json/VI_USE_ATTRIBUT_DETAIL"));
var _VI_JQX_CLASS = _interopRequireDefault(require("@/assets/json/VI_JQX_CLASS"));
var _VI_CUST_NATUER = _interopRequireDefault(require("@/assets/json/VI_CUST_NATUER"));
var _VI_FREE_TAX_REASON = _interopRequireDefault(require("@/assets/json/VI_FREE_TAX_REASON"));
var _VI_CUT_TAX_REASON = _interopRequireDefault(require("@/assets/json/VI_CUT_TAX_REASON"));
var _VI_NATIONAL_SOURCE = _interopRequireDefault(require("@/assets/json/VI_NATIONAL_SOURCE"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  mixins: [_subapp.default],
  mounted: function mounted() {
    var _this = this;
    if (!_.isEmpty(_VI_VEHICLE_KIND.default)) localStorage.setItem('VI_VEHICLE_KIND', JSON.stringify(_VI_VEHICLE_KIND.default));
    if (!_.isEmpty(_VI_USE_ATTRIBUTE.default)) localStorage.setItem('VI_USE_ATTRIBUTE', JSON.stringify(_VI_USE_ATTRIBUTE.default));
    if (!_.isEmpty(_VI_CERTIFICATE_TYPE.default)) localStorage.setItem('VI_CERTIFICATE_TYPE', JSON.stringify(_VI_CERTIFICATE_TYPE.default));
    if (!_.isEmpty(_VI_PLATE_TYPE.default)) localStorage.setItem('VI_PLATE_TYPE', JSON.stringify(_VI_PLATE_TYPE.default));
    if (!_.isEmpty(_VI_PLATE_COLOR.default)) localStorage.setItem('VI_PLATE_COLOR', JSON.stringify(_VI_PLATE_COLOR.default));
    if (!_.isEmpty(_VI_ENGRGY_TYPE.default)) localStorage.setItem('VI_ENGRGY_TYPE', JSON.stringify(_VI_ENGRGY_TYPE.default));
    if (!_.isEmpty(_VI_USE_ATTRIBUT_DETAIL.default)) localStorage.setItem('VI_USE_ATTRIBUT_DETAIL', JSON.stringify(_VI_USE_ATTRIBUT_DETAIL.default));
    if (!_.isEmpty(_VI_JQX_CLASS.default)) localStorage.setItem('VI_JQX_CLASS', JSON.stringify(_VI_JQX_CLASS.default));
    if (!_.isEmpty(_VI_CUST_NATUER.default)) localStorage.setItem('VI_CUST_NATUER', JSON.stringify(_VI_CUST_NATUER.default));
    if (!_.isEmpty(_VI_FREE_TAX_REASON.default)) localStorage.setItem('VI_FREE_TAX_REASON', JSON.stringify(_VI_FREE_TAX_REASON.default));
    if (!_.isEmpty(_VI_CUT_TAX_REASON.default)) localStorage.setItem('VI_CUT_TAX_REASON', JSON.stringify(_VI_CUT_TAX_REASON.default));
    if (!_.isEmpty(_VI_NATIONAL_SOURCE.default)) localStorage.setItem('VI_NATIONAL_SOURCE', JSON.stringify(_VI_NATIONAL_SOURCE.default));

    // let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    // if (userInfo && userInfo.user) {
    //     setWaterMark((userInfo.user.account || '') + '@' + (userInfo.user.userName || ''), (userInfo.data && userInfo.data.orgName ? userInfo.data.orgName : ''), parseTime(new Date(), '{y}{m}{d}{h}'));
    // } else {
    //     removeWatermark();
    // }
    document.title = localStorage.getItem('title') || '';
    // this.refreshToken();
    // this.queryByDomain();
    setInterval(function () {
      _this.refreshToken();
    }, 1000 * 60 * 5);
    //设置系统主题色
    if (localStorage.getItem('themeColor')) {
      var themeColor = localStorage.getItem('themeColor');
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: themeColor
      });
    }
  },
  methods: {
    // 刷新站点配置信息
    queryByDomain: function queryByDomain() {
      var _this2 = this;
      var domain = location.host;
      _common.default.queryConfigByDomain({
        domain: domain
      }).then(function (res) {
        if (!_.isEmpty(res.data)) {
          localStorage.setItem('imgSrc', res.data.backgroundUrl || '');
          // localStorage.setItem("title", res.data.portalName || "");
          localStorage.setItem("logoUrl", res.data.logoUrl || "");
          localStorage.setItem("pictureUrl", res.data.pictureUrl || "");
          // localStorage.setItem("portalId", res.data.portalId || "0");
          localStorage.setItem("siteIcon", res.data.iconUrl || "#");
          localStorage.setItem("themeColor", res.data.themeColor);
          if (res.data.themeColor) {
            _this2.$store.dispatch("settings/changeSetting", {
              key: "theme",
              value: res.data.themeColor
            });
          }
          // localStorage.setItem("bizType", res.data.bizType);
        }
      });
    },
    // 刷新token
    refreshToken: function refreshToken() {
      var _this3 = this;
      var token = localStorage.getItem('token') || "";
      if (!token) return;
      _users.default.refreshToken().then(function (res) {
        if (res.code == 200 && res.data) {
          localStorage.setItem('token', res.data.accessToken);
        } else {
          if (res.code == 401) {
            var hasLogout = localStorage.getItem('logout') || false;
            _this3.$store.dispatch('user/logout');
            _this3.$store.dispatch('tagsView/delAllCachedViews', _this3.$router);
            if (hasLogout) {
              _this3.$router.push("/login?logout=1");
            } else {
              _this3.$router.push("/login");
            }
          }
        }
      });
    }
  }
};