"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _signature_pad = _interopRequireDefault(require("signature_pad"));
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "signConfirm",
  data: function data() {
    return {
      openApi: process.env.VUE_APP_OPEN_API,
      canvas: null,
      radio: "#000",
      height: 50,
      direction: false,
      // true 代表横屏, false 代表'竖屏' -- 但是亲测没有效果
      el: "",
      // canvas dom
      ctx: "",
      // canvas context
      background: "white",
      // canvas background-color
      color: "#000",
      // 绘制时线条的颜色
      linewidth: 2,
      // 线条的宽度
      liColors: ["#000"],
      drawCheck: false,
      //用来判断是否签字
      clientHeight: document.documentElement.clientHeight,
      clientWidth: document.documentElement.clientWidth,
      loading: false,
      base64: null,
      imgBase64: null,
      prohibit: false,
      fangxiang: null,
      isDrawCheck: false
    };
  },
  watch: {
    // 监听路由是否变化
    '$route': function $route(to, from) {
      if (this.signaturePad) this.resetCanvas();
      this.contractNo = this.$route.query.contractNo || null;
      this.authCode = this.$route.query.auth_code || this.$route.query.authCode || null;
    }
  },
  mounted: function mounted() {
    window.addEventListener("resize", this.renderResize, false);
    this.init();
  },
  methods: {
    renderResize: function renderResize() {
      var portrait = window.matchMedia("(orientation: portrait)").matches ? '竖屏' : '横屏';
      setTimeout(function () {
        var height = window.innerHeight || document.documentElement.clientHeight;
        console.log('【' + portrait + 'height:', height, '】');
      }, 500);
    },
    init: function init() {
      var _this = this;
      this.contractNo = this.$route.query.contractNo || null;
      this.authCode = this.$route.query.auth_code || this.$route.query.authCode || null;
      window.onresize = function () {
        _this.clientHeight = document.documentElement.clientHeight;
        _this.clientWidth = document.documentElement.clientWidth;
        _this.draw();
        return;
      };
      this.draw();
    },
    // 添加绘制 line
    draw: function draw() {
      if (!this.prohibit) {
        this.$refs.signHandle.addEventListener("touchstart", function (e) {
          return e.preventDefault();
        }, {
          passive: false
        });
        this.el = this.$refs.signHandle;
        console.log(this.prohibit);
        this.initCanvas();
      }
    },
    // 初始化canvas配置
    initCanvas: function initCanvas() {
      var height = this.height,
        direction = this.direction,
        el = this.el;
      el.width = this.clientWidth;
      el.height = this.clientHeight;
      this.ctx = el.getContext("2d");
      this.setCanvas();
      this.drawStart();
      this.drawing();
      this.drawEnd();
    },
    // 配置 canvas
    setCanvas: function setCanvas() {
      var ctx = this.ctx,
        height = this.height,
        direction = this.direction;
      // 设置背景色
      ctx.fillStyle = this.background;
      ctx.fillRect(0, 0, this.clientWidth, this.clientHeight);
      // 设置线条颜色
      ctx.strokeStyle = this.color;
      // 设置线宽
      ctx.lineWidth = this.linewidth;
      // 设置线条两头的结束点和开始点是圆形的
      ctx.lineCap = "round";
    },
    // 开始绘制
    drawStart: function drawStart() {
      var _this2 = this;
      var el = this.el,
        ctx = this.ctx;
      el.addEventListener("touchstart", function (e) {
        ctx.beginPath();
        ctx.moveTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
        _this2.drawCheck = true; //代表签过字
      }, false);
    },
    // 绘制中
    drawing: function drawing() {
      var el = this.el,
        ctx = this.ctx;
      el.addEventListener("touchmove", function (e) {
        ctx.lineTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
        ctx.stroke();
      }, false);
    },
    // 绘制结束
    drawEnd: function drawEnd() {
      var el = this.el,
        ctx = this.ctx;
      el.addEventListener("touchend", function () {
        return ctx.closePath();
      }, false);
    },
    // 清空
    clearHandle: function clearHandle() {
      this.initCanvas();
      this.drawCheck = false; //代表签过字
    },
    onComfirm: function onComfirm() {
      var _this3 = this;
      if (this.drawCheck == false) {
        this.prohibit = true;
        this.isDrawCheck = true;
        setTimeout(function () {
          _this3.isDrawCheck = false;
        }, 2000);
        return;
      }
      this.saveImg();
      // this.adminSave()
    },
    // 保存信息
    saveImg: function saveImg() {
      var img = new Image();
      this.loading = true;
      var imgBase64 = this.el.toDataURL();
      this.imgBase64 = imgBase64;
      // console.log('没处理前的base64', imgBase64)
      this.rotateBase64Img(imgBase64, -90);
    },
    //签完名的图片90°旋转处理
    rotateBase64Img: function rotateBase64Img(src, edg) {
      var that = this;
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = edg % 360 + 360);
      var quadrant = edg / 90 % 4; //旋转象限
      var cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function () {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx && ctx.translate(size, size);
        ctx && ctx.rotate(edg * Math.PI / 180);
        //drawImage向画布上绘制图片
        ctx && ctx.drawImage(image, 0, 0);
        //getImageData() 复制画布上指定矩形的像素数据
        var imgData = ctx && ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        //putImageData() 将图像数据放回画布
        ctx && ctx.putImageData(imgData, 0, 0);
        // callback(canvas.toDataURL("image/png"));
        var _base64 = canvas.toDataURL("image/png").split(",")[1];
        // console.log('最终的base64', _base64)
        if (!_.isEmpty(_base64)) {
          that.base64 = _base64;
          that.save();
        }
        ;
        // this.base64 = canvas.toDataURL("image/png").split(",")[1];
        // console.log('最终的base64', this.base64)
        // return ;
      };
    },
    save: function save() {
      var _this4 = this;
      this.loading = true;
      // this.canvasUrl = this.signaturePad.toDataURL();
      var base64Content = this.base64;
      var data = {
        file: base64Content,
        fileName: "".concat(this.contractNo, "_").concat(this.authCode, "\u7B7E\u540D\u5185\u5BB9.png")
      };
      // console.log('data', data)
      // return
      (0, _axios.default)({
        method: "post",
        url: "".concat(this.openApi, "/open/selfsigncontract/confirmSignature?contractNo=").concat(this.contractNo, "&authCode=").concat(this.authCode),
        data: data
      }).then(function (response) {
        _this4.loading = false;
        if (response.data.code == 200) {
          if (response.data.data) {
            var resData = response.data.data;
            if (resData.signature.singFlag) {
              window.location.href = resData.signature.returnUrl;
            }
          }
          ;
        } else {
          _this4.$message.warning(response.data.msg);
        }
        ;
      }).catch(function (error) {
        _this4.loading = false;
        console.log(error, 'error');
      });
    }
  }
};